import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import React, { useEffect, useState } from "react"

import FacebookIcon from "~/images/social/facebook.svg"
import MailIcon from "~/images/social/mail.svg"
import PinterestIcon from "~/images/social/pinterest.svg"
import TwitterIcon from "~/images/social/twitter.svg"
import WhatsAppIcon from "~/images/social/whatsapp.svg"
import useWindowSize from "../../helpers/hooks/useWindowSize.js"

const Share = ({ alignLeft, title, description, media }) => {
  const sizes = useWindowSize()
  const [url, setUrl] = useState("")
  useEffect(() => {
    setUrl(window.location.href)
  }, [])
  return (
    <div className={`share ${alignLeft ? "share--left" : ""}`}>
      Partager sur&nbsp;:
      <FacebookShareButton
        className="share__link"
        url={url}
        title={title}
        quote={description}
      >
        <img src={FacebookIcon} alt="" />
      </FacebookShareButton>
      {!sizes.isMobile && (
        <>
          <PinterestShareButton
            className="share__link"
            url={url}
            title={title}
            media={media}
          >
            <img src={PinterestIcon} alt="" />
          </PinterestShareButton>
        </>
      )}
      <WhatsappShareButton className="share__link" url={url} title={title}>
        <img src={WhatsAppIcon} alt="" />
      </WhatsappShareButton>
      <TwitterShareButton className="share__link" url={url} title={title}>
        <img src={TwitterIcon} alt="" />
      </TwitterShareButton>
      <a className="share__link" href={`mailto:?subject=${title}&body=${url}`}>
        <img src={MailIcon} alt="" />
      </a>
    </div>
  )
}

export default Share
