import Apple from "../../images/icons/podcast/apple.svg"
import Ausha from "../../images/icons/podcast/ausha.svg"
import React from "react"
import Spotify from "../../images/icons/podcast/spotify.svg"

const Podcast = ({ infos }) => {
  if (!!infos && !!infos.embed) {
    return (
      <div className="podcastContenair">
        <div
          className="podcastContenair__embed"
          dangerouslySetInnerHTML={{ __html: infos.embed }}
        />
        <div className="podcastContenair__links">
          <span>Écouter sur : </span>
          {!!infos.links && !!infos.links.spotify && (
            <a target="_blank" rel="noreferrer" href={infos.links.spotify}>
              <img src={Spotify} alt="spotify" />
            </a>
          )}
          {!!infos.links && !!infos.links.apple && (
            <a target="_blank" rel="noreferrer" href={infos.links.apple}>
              <img src={Apple} alt="Apple" />
            </a>
          )}
          {!!infos.links && !!infos.links.ausha && (
            <a target="_blank" rel="noreferrer" href={infos.links.ausha}>
              <img src={Ausha} alt="ausha" />
            </a>
          )}
        </div>
      </div>
    )
  }

  return ""
}

export default Podcast
