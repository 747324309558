import ArticleItem from "../article-item/article-item.js"
import React from "react"

const RelatedArticles = ({ articles }) => {
  return (
    <>
      {!!articles && (
        <section className={"related-articles"}>
          <h3>Articles qui pourraient vous intéresser</h3>
          {articles
            .filter(item => item != null)
            .map((props, i) => (
              <ArticleItem {...props} key={i} />
            ))}
        </section>
      )}
    </>
  )
}

export default RelatedArticles
