import { CATEGORY, LOGO_PATH, PREVIOUS_HOSTNAME } from "../../constants.js"
import React, { useEffect, useState } from "react"

import Difficulty from "../images/icons/recipe/difficulty.svg"
import Layout from "../components/layout/layout.js"
import People from "../images/icons/recipe/people.svg"
import Placeholder from "../images/image-placeholder.jpg"
import Podcast from "../components/podcast/podcast.js"
import RelatedArticles from "../components/related-articles/related-articles.js"
import SEO from "../components/seo.js"
import Share from "../components/share/share.js"
import StripeText from "../components/decoration/stripe-text.js"
import Time from "../images/icons/recipe/time.svg"
import formatDate from "../helpers/formatDate.js"
import useWindowSize from "../helpers/hooks/useWindowSize.js"

import Lazyload from "react-lazyload"
import Img from '../helpers/img'

// Single BLog post page
const BlogPost = ({
  location,
  pageContext: {
    title,
    content,
    date,
    categories,
    featuredImage,
    relatedPosts,
    recipe_attributes,
    podcast_attributes,
    seo,
  },
}) => {
  const sizes = useWindowSize()

  // console.log(pageContext);
  const url = typeof window !== 'undefined' ? window.location.href : '';

  const [parsedContent, setParsedContent] = useState(content)
  const [media, setMedia] = useState(
    `${process.env.GATSBY_HOSTNAME}${LOGO_PATH}`
  )
  useEffect(() => {
    setMedia(getMedia(seo, featuredImage))
    setParsedContent(changeLinks(content))
  }, [seo, featuredImage, content])

  return (
    <Layout location={location}>
      <SEO meta={seo} type='article' image={(featuredImage?.node?.sourceUrl || `${process.env.GATSBY_HOSTNAME}/${LOGO_PATH}`).replace('https://funky.buddybuddy.io/wp-content/uploads/', 'https://cdn.funkyveggie.fr/wp-content/uploads/')} />
      
      <div className={!!recipe_attributes && recipe_attributes.cookTime ? "h-recipe container" : "container"}>
        <div id="page-blogpost" className={"page-blogpost"}>
          <section className={"page-blogpost__wrapper"}>
            <div className={"page-blogpost__featured"}>
              {extractImageUrlFromHtml(content) ? (
                // <img
                //   className={"page-blogpost__image"}
                //   srcSet={featuredImage.node.srcSet}
                //   sizes={featuredImage.node.sizes}
                //   alt={"Illustration article"}
                // />
                <Lazyload>
                  <Img 
                    source={featuredImage?.node?.sourceUrl || extractImageUrlFromHtml(content)}
                    classe='page-blogpost__image u-photo'
                    width={1200}
                  />
                </Lazyload>
              ) : (
                <img
                  className={"page-blogpost__image"}
                  src={Placeholder}
                  alt={"Placeholder illustration article"}
                />
              )}
            </div>

            <article className={"page-blogpost__article"}>
              <span className={"page-blogpost__date"}>{formatDate(date)}</span>
              <h1 className={"page-blogpost__title p-name"}>{title}</h1>
              <StripeText
                className={"page-blogpost__category"}
                text={categories.nodes[0].name}
                color={
                  (CATEGORY[categories.nodes[0].slug] &&
                    CATEGORY[categories.nodes[0].slug].color) ||
                  "#333333"
                }
              />
              {!!recipe_attributes && recipe_attributes.cookTime ? (
                <div className="page-blogpost__recipeCtt">
                  {sizes.width <= 768 && (
                    <Share
                      title={seo ? seo.opengraphTitle : title}
                      description={seo ? seo.opengraphDescription : title}
                      media={media}
                    />
                  )}
                  <div className="page-blogpost__recipeCtt__infos">
                    {!!recipe_attributes && recipe_attributes.cookTime && (
                      <div className="page-blogpost__recipeCtt__top">
                        <img src={Time} alt="" />
                        <span>
                          Temps de préparation :
                          <strong className='dt-duration'>{recipe_attributes.cookTime}</strong>
                        </span>
                      </div>
                    )}
                    {!!recipe_attributes && recipe_attributes.people && (
                      <div className="page-blogpost__recipeCtt__top">
                        <img src={People} alt="" />
                        <span>
                          Préparation pour :
                          <strong className='p-yield'>{recipe_attributes.people}</strong>
                        </span>
                      </div>
                    )}
                    {!!recipe_attributes && recipe_attributes.difficulty && (
                      <div className="page-blogpost__recipeCtt__top">
                        <img src={Difficulty} alt="" />
                        <span>
                          Difficulté :
                          <strong>{recipe_attributes.difficulty}</strong>
                        </span>
                      </div>
                    )}
                    <div className="page-blogpost__recipeCtt__ingredients">
                      <span className="title">Ingrédients</span>
                      {recipe_attributes.ingredients.map((item, i) => (
                        <div className="items p-ingredient" key={i}>
                          <strong>{item.bold}</strong> {item.texteClassique}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={"page-blogpost__wrapper"}>
                    {!sizes.isMobile && (
                      <Share
                        alignLeft={true}
                        title={seo ? seo.opengraphTitle : title}
                        description={seo ? seo.opengraphDescription : title}
                        media={media}
                      />
                    )}
                    <Podcast infos={podcast_attributes} />
                    <div
                      className={!!recipe_attributes && recipe_attributes.cookTime ? "page-blogpost__content e-instructions" : "page-blogpost__content"}
                      dangerouslySetInnerHTML={{ __html: parsedContent }}
                    />
                    <Share
                      title={seo ? seo.opengraphTitle : title}
                      description={seo ? seo.opengraphDescription : title}
                      media={media}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <Share
                    title={seo ? seo.opengraphTitle : title}
                    description={seo ? seo.opengraphDescription : title}
                    media={media}
                  />
                  <Podcast infos={podcast_attributes} />
                  <div
                    className={"page-blogpost__content"}
                    dangerouslySetInnerHTML={{ __html: parsedContent }}
                  />
                  <Share
                    title={seo ? seo.opengraphTitle : title}
                    description={seo ? seo.opengraphDescription : title}
                    media={media}
                  />
                </>
              )}
            </article>
            {!sizes.isMobile && <RelatedArticles articles={relatedPosts} />}
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost

// Get a media for Pinterest Sharing
const getMedia = (seo, featuredImage) => {
  if (
    seo &&
    seo.opengraphImage &&
    seo.opengraphImage.localFile &&
    seo.opengraphImage.localFile.url
  )
    return seo.opengraphImage.localFile.url
  if (featuredImage && featuredImage.node && featuredImage.node.sourceUrl)
    return featuredImage.node.sourceUrl
  return `${process.env.GATSBY_HOSTNAME}${LOGO_PATH}`
}

// Rewrite links and images in post content replacing all links by env links
const changeLinks = content => {
  if (!!content) {
    let parsedContent = content
      .split('https://funky.buddybuddy.io/wp-content/uploads/')
      .join('https://cdn.funkyveggie.fr/wp-content/uploads/')


    parsedContent = content
      .split(PREVIOUS_HOSTNAME)
      .join(process.env.GATSBY_HOSTNAME)
    // As images links should still points to actual production website,
    // rewrite those bads links as it was before we changed it with previous lines
    // parsedContent = parsedContent
    //   .split(`${process.env.GATSBY_HOSTNAME}/wp-content/uploads/`)
    //   .join(process.env.GATSBY_FUNKY_IMG_URL)
    return parsedContent
  }
  return content
}

const extractImageUrlFromHtml = (htmlContent) => {
  const imgTagRegex = /<img[^>]+src="([^">]+)"/;
  const match = htmlContent.match(imgTagRegex);

  if (match && match[1]) {
    return match[1];
  } else {
    return `${process.env.GATSBY_HOSTNAME}/${LOGO_PATH}`;
  }
}
